import { FunctionComponent } from "react";
import { createRoot } from "react-dom/client";

export function mountRoute<T>(Component: FunctionComponent<T>) {
  const moutingPoint = document.getElementById("root");
  if (!moutingPoint) return;
  // @ts-ignore
  const params = JSON.parse(window?.__handoff__?.params || `{}`) as T;

  createRoot(moutingPoint).render(null);
  // store.init({});
  // @ts-ignore
  createRoot(moutingPoint).render(<Component params={params} />);
}
